import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllProductionAreas } from 'services/productionAreaApiService';

// initial state
const initialState = {
    productionAreas: [],
    totalCount: 0,
    openCount: 0,
    closedCount: 0,
    loading: null,
    error: null
};

export const getAllProductionAreasThunk = createAsyncThunk('productionAreas/getAllProductionAreas', async () => {
    const response = await getAllProductionAreas();
    return response;
});

const productionAreasSlice = createSlice({
    name: 'productionAreas',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllProductionAreasThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.productionAreas = action.payload.productionAreas;
                state.totalCount = action.payload.totalCount;
                state.openCount = action.payload.openCount;
                state.closedCount = action.payload.closedCount;
            })
            .addCase(getAllProductionAreasThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllProductionAreasThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export default productionAreasSlice.reducer;
