import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllLevels } from 'services/ReferenceLists/levelApiService';

// initial state
const initialState = {
    levels: [],
    loading: null,
    error: null
};

export const getAllLevelsThunk = createAsyncThunk('levels/getAllLevels', async () => {
    const response = await getAllLevels();
    return response.data;
});

const levelsSlice = createSlice({
    name: 'levels',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllLevelsThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.levels = action.payload.levels;
            })
            .addCase(getAllLevelsThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllLevelsThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export default levelsSlice.reducer;
