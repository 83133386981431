import { Stack, Typography, Divider, Box } from '@mui/material';

const Logo = () => {
    return (
        <Box
            component="img"
            sx={{ padding: '20px', width: '100%' }}
            alt="The house from the offer."
            src={process.env.PUBLIC_URL + '/BGL_logo_256x172.png'}
        />
    );
};

export default Logo;
