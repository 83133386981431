import axios from 'axios';

export const getAllProductionAreas = async () => {
    const response = await axios.get(`/v1/ProductionArea/GetAllProductionAreas`);
    return {
        totalCount: response.data.totalCount,
        closedCount: response.data.closedCount,
        openCount: response.data.openCount,
        productionAreas: response.data.productionAreas
    };
};

export const getProductionAreaById = async (id) => {
    const response = await axios.get(`/v1/ProductionArea/GetProductionAreaById?Id=${id}`);
    return response.data;
};

export const getProductionAreasCount = async () => {
    const response = await axios.get(`/v1/ProductionArea/GetProductionAreasCount`);
    return response.data;
};

export const addProductionArea = async (productionArea) => {
    const response = await axios.post(`/v1/ProductionArea/AddProductionArea`, productionArea);
    return response;
};

export const deleteProductionArea = async (productionAreaId) => {
    const response = await axios.delete(`/v1/ProductionArea/DeleteProductionArea`, {
        data: { id: productionAreaId }
    });
    return response;
};

export const editProductionArea = async (productionArea) => {
    const response = await axios.put(`/v1/ProductionArea/UpdateProductionArea`, productionArea);
    return response;
};
