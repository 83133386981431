import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const Dashboard = Loadable(lazy(() => import('pages/dashboard')));
const ProductionAreaList = Loadable(lazy(() => import('pages/production-areas/list')));
const ProductionAreaDetail = Loadable(lazy(() => import('pages/production-areas/detail')));
const DrillAndBlastDesign = Loadable(lazy(() => import('pages/production-areas/drillAndBlastDesign')));
const ReservePerformance = Loadable(lazy(() => import('pages/production-areas/reservePerformance')));
const ProductionTracking = Loadable(lazy(() => import('pages/production-areas/productionTracking')));
const CmsSolidFinal = Loadable(lazy(() => import('pages/production-areas/cmsSolidFinal')));
const ReconciliationSummaryTable = Loadable(lazy(() => import('pages/production-areas/reconciliationSummaryTable')));
const ReconciliationSummaryReport = Loadable(lazy(() => import('pages/production-areas/reconciliationSummaryReport')));
const Administration = Loadable(lazy(() => import('pages/administration/index')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    breadcrumb: 'Dashboard',
    children: [
        {
            index: true,
            element: <Dashboard />
        },
        {
            path: '/production-areas',
            element: <ProductionAreaList />,
            breadcrumb: 'Production Areas'
        },
        {
            path: '/production-areas/:productionAreaId',
            element: <ProductionAreaDetail />,
            breadcrumb: 'Production Area'
        },
        {
            path: '/production-areas/:productionAreaId/drill-and-blast-design',
            element: <DrillAndBlastDesign />,
            breadcrumb: 'Drill And Blast Design'
        },
        {
            path: '/production-areas/:productionAreaId/reserve-performance',
            element: <ReservePerformance />,
            breadcrumb: 'Reserve Performance'
        },
        {
            path: '/production-areas/:productionAreaId/production-tracking',
            element: <ProductionTracking />,
            breadcrumb: 'Production Tracking'
        },
        {
            path: '/production-areas/:productionAreaId/cms-solid-final',
            element: <CmsSolidFinal />,
            breadcrumb: 'CMS Solid - Final'
        },
        {
            path: '/production-areas/:productionAreaId/reconciliation-summary-table',
            element: <ReconciliationSummaryTable />,
            breadcrumb: 'Reconciliation Summary Table'
        },
        {
            path: '/production-areas/:productionAreaId/reconciliation-summary-report',
            element: <ReconciliationSummaryReport />,
            breadcrumb: 'Reconciliation Summary Report'
        },
        {
            path: '/administration',
            element: <Administration />,
            breadcrumb: 'Administration'
        }
    ]
};

export default MainRoutes;
