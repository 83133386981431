import { useMsal, useAccount } from '@azure/msal-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { AppSettingsContext } from 'context';
import Alert from '@mui/material/Alert';

const RequestInterceptor = ({ children }) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const settings = useContext(AppSettingsContext);
    const [isApiError, setIsApiError] = useState(false);

    axios.defaults.baseURL = settings.API_ENDPOINT;

    axios.interceptors.request.use(async (config) => {
        if (!account) {
            throw Error('No active account! Verify a user has been signed in.');
        }

        const response = await instance.acquireTokenSilent({
            scopes: [settings.AUTH.scopes.read, settings.AUTH.scopes.write],
            account
        });

        const bearer = `Bearer ${response.accessToken}`;
        config.headers.Authorization = bearer;

        return config;
    });

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            console.error(error);
            //setIsApiError(true);
            throw error;
        }
    );

    return (
        <>
            {children}
            {isApiError && (
                <Alert severity="error" sx={{ justifyContent: 'right' }}>
                    Unable to communicate with the API. Please contact IT Support.
                </Alert>
            )}
        </>
    );
};

RequestInterceptor.propTypes = {
    children: PropTypes.node
};

export default RequestInterceptor;
