import { useMsal, useAccount } from '@azure/msal-react';
import { useState, useEffect, useContext } from 'react';
import { AuthContext, AppSettingsContext } from './context';
import PropTypes from 'prop-types';
import { Backdrop, Typography, Stack } from '@mui/material';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

const MsalGroupAuthorisation = (props) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const { children } = props;
    const [authContext, setAuthContext] = useContext(AuthContext);
    const [authorized, setAuthorized] = useState(false);
    const settings = useContext(AppSettingsContext);

    useEffect(() => {
        const getToken = async () => {
            const response = await instance.acquireTokenSilent({
                scopes: [settings.AUTH.scopes.read, settings.AUTH.scopes.write],
                account: account
            });

            if (!response || !response.idTokenClaims) {
                setAuthorized(false);
            } else {
                var allGroups = [settings.GROUPS.User, settings.GROUPS.Supervisor, settings.GROUPS.Administrator];
                setAuthContext({
                    ...authContext,
                    isAdministrator: response.idTokenClaims.groups?.includes(settings.GROUPS.Administrator) || false,
                    isSupervisor: response.idTokenClaims.groups?.includes(settings.GROUPS.Supervisor) || false,
                    isUser: response.idTokenClaims.groups?.some((g) => allGroups.includes(g)) || false
                });
            }
        };
        getToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, settings, account]);

    useEffect(() => {
        setAuthorized(authContext.isAdministrator || authContext.isSupervisor || authContext.isUser);
    }, [authContext]);

    return (
        <>
            {authorized && <>{children}</>}
            {!authorized && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <Stack spacing={3} alignItems="center">
                        <GppMaybeIcon sx={{ fontSize: 80 }} />
                        <Typography variant="h4">You are unauthorised to access this site</Typography>
                        <Typography variant="h5">Please contact your administrator</Typography>
                    </Stack>
                </Backdrop>
            )}
        </>
    );
};

MsalGroupAuthorisation.propTypes = {
    children: PropTypes.node
};

export default MsalGroupAuthorisation;
