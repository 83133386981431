import axios from 'axios';

export const getAllStopeNumbers = async () => {
    const response = await axios.get(`/v1/StopeNumber/GetAllStopeNumbers`);
    return response;
};

export const getStopeNumberById = async (id) => {
    const response = await axios.get(`/v1/StopeNumber/GetStopeNumberById?Id=${id}`);
    return response.data;
};

export const addStopeNumber = async (stopeNumber) => {
    const response = await axios.post(`/v1/StopeNumber/AddStopeNumber`, stopeNumber);
    return response;
};

export const editStopeNumber = async (stopeNumber) => {
    const response = await axios.put(`/v1/StopeNumber/UpdateStopeNumber`, stopeNumber);
    return response;
};

export const deleteStopeNumber = async (stopeNumberId) => {
    const response = await axios.delete(`/v1/StopeNumber/DeleteStopeNumber`, {
        data: { id: stopeNumberId }
    });
    return response;
};
