import axios from 'axios';

export const getAllOperations = async () => {
    const response = await axios.get(`/v1/Operation/GetAllOperations`);
    return response;
};

export const getOperationById = async (id) => {
    const response = await axios.get(`/v1/Operation/GetOperationById?Id=${id}`);
    return response.data;
};

export const addOperation = async (operation) => {
    const response = await axios.post(`/v1/Operation/AddOperation`, operation);
    return response;
};

export const editOperation = async (operation) => {
    const response = await axios.put(`/v1/Operation/UpdateOperation`, operation);
    return response;
};

export const deleteOperation = async (operationId) => {
    const response = await axios.delete(`/v1/Operation/DeleteOperation`, {
        data: { id: operationId }
    });
    return response;
};
