import axios from 'axios';

export const getAllMines = async () => {
    const response = await axios.get(`/v1/Mine/GetAllMines`);
    return response;
};

export const getMineById = async (id) => {
    const response = await axios.get(`/v1/Mine/GetMineById?Id=${id}`);
    return response.data;
};

export const addMine = async (mine) => {
    const response = await axios.post(`/v1/Mine/AddMine`, mine);
    return response;
};

export const editMine = async (mine) => {
    const response = await axios.put(`/v1/Mine/UpdateMine`, mine);
    return response;
};

export const deleteMine = async (mineId) => {
    const response = await axios.delete(`/v1/Mine/DeleteMine`, {
        data: { id: mineId }
    });
    return response;
};
