// project import
import navigation from './navigation';

const menuItems = {
    items: navigation,
    getNavItemId: (pathname) => {
        const navItem = navigation.find((item) => {
            return item.url === pathname;
        });
        return navItem ? navItem.id : null;
    }
};

export default menuItems;
