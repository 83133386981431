// project import
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { Typography, Stack } from '@mui/material';
import { AppSettingsContext } from 'context';
import { useContext } from 'react';

const DrawerContent = () => {
    const settings = useContext(AppSettingsContext);

    return (
        <>
            <SimpleBar
                sx={{
                    '& .simplebar-content': {
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }}
            >
                <Navigation />
            </SimpleBar>
            <Stack spacing={1} alignItems="center">
                <Typography variant="body1Alert">{settings.ENVIRONMENT}</Typography>
                <Typography variant="body1">v{settings.VERSION}</Typography>
                <Typography variant="body1">Copywrite &#169; {new Date().getFullYear()}</Typography>
            </Stack>
        </>
    );
};

export default DrawerContent;
