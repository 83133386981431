// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    padding: 12,
                    borderColor: theme.palette.divider,
                    textAlign: 'center'
                },
                head: {
                    fontWeight: 600,
                    paddingTop: 20,
                    paddingBottom: 20,
                    backgroundColor: theme.palette.primary.light,
                    borderBottom: 0
                }
            },
            variants: [
                {
                    props: { variant: 'cellHeader' },
                    style: {
                        fontWeight: 600,
                        backgroundColor: theme.palette.primary.light,
                        textAlign: 'center',
                        borderBottom: 0
                    }
                },
                {
                    props: { variant: 'cellCalculated' },
                    style: {
                        fontWeight: 600,
                        textAlign: 'center'
                    }
                },
                {
                    props: { variant: 'cellHeaderFullColSpan' },
                    style: {
                        fontWeight: 600,
                        textAlign: 'left',
                        textTransform: 'uppercase'
                    }
                },
                {
                    props: { variant: 'auditRow' },
                    style: {
                        paddingBottom: 0,
                        paddingTop: 0,
                        borderBottom: 0
                    }
                }
            ]
        }
    };
}
