// assets
import { HomeOutlined, GoldOutlined, AreaChartOutlined, LockOutlined } from '@ant-design/icons';

// icons
const icons = {
    HomeOutlined,
    GoldOutlined,
    AreaChartOutlined,
    LockOutlined
};

const navigation = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/',
        icon: icons.HomeOutlined,
        breadcrumbs: false,
        requiresAdmin: false
    },
    {
        id: 'production-areas',
        title: 'Production Areas',
        type: 'item',
        url: '/production-areas',
        icon: icons.GoldOutlined,
        breadcrumbs: true,
        requiresAdmin: false
    },
    {
        id: 'administration',
        title: 'Administration',
        type: 'item',
        url: '/administration',
        icon: icons.LockOutlined,
        breadcrumbs: true,
        requiresAdmin: true
    }
];

export default navigation;
