import axios from 'axios';

export const getAllLevels = async () => {
    const response = await axios.get(`/v1/Level/GetAllLevels`);
    return response;
};

export const getLevelById = async (id) => {
    const response = await axios.get(`/v1/Level/GetLevelById?Id=${id}`);
    return response.data;
};

export const addLevel = async (level) => {
    const response = await axios.post(`/v1/Level/AddLevel`, level);
    return response;
};

export const editLevel = async (level) => {
    const response = await axios.put(`/v1/Level/UpdateLevel`, level);
    return response;
};

export const deleteLevel = async (levelId) => {
    const response = await axios.delete(`/v1/Level/DeleteLevel`, {
        data: { id: levelId }
    });
    return response;
};
