import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllStopeNumbers } from 'services/ReferenceLists/stopeNumberApiService';

// initial state
const initialState = {
    stopeNumbers: [],
    loading: null,
    error: null
};

export const getAllStopeNumbersThunk = createAsyncThunk('stopeNumbers/getAllStopeNumbers', async () => {
    const response = await getAllStopeNumbers();
    return response.data;
});

const stopeNumbersSlice = createSlice({
    name: 'stopeNumbers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllStopeNumbersThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.stopeNumbers = action.payload.stopeNumbers;
            })
            .addCase(getAllStopeNumbersThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllStopeNumbersThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export default stopeNumbersSlice.reducer;
