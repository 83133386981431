import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import Drawer from './Drawer';
import Header from './Header';
import menuItems from 'menu-items';
import { activeItem } from 'store/reducers/menu';
import { openDrawer } from 'store/reducers/menu';
import { getAllGlobalValues } from 'services/ReferenceLists/globalValueApiService';
import { GlobalValuesContext } from 'context';

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    const location = useLocation();
    useEffect(() => {
        const locationId = menuItems.getNavItemId(location.pathname);
        if (locationId) dispatch(activeItem({ openItem: [locationId] }));
    }, [dispatch, location.pathname]);

    const [globalValues, setGlobalValues] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            var response = await getAllGlobalValues();
            setGlobalValues(response.globalValues);
        };
        fetchData();
    }, []);
    return (
        <GlobalValuesContext.Provider value={globalValues}>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
                <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                    <Toolbar />
                    <Outlet />
                </Box>
            </Box>
        </GlobalValuesContext.Provider>
    );
};

export default MainLayout;
