import axios from 'axios';

export const getAllGlobalValues = async () => {
    const response = await axios.get(`/v1/GlobalValue/GetAllGlobalValues`);
    return response.data;
};

export const getGlobalValueById = async (id) => {
    const response = await axios.get(`/v1/GlobalValue/GetGlobalValueById?Id=${id}`);
    return response.data;
};

export const addGlobalValue = async (globalValue) => {
    const response = await axios.post(`/v1/GlobalValue/AddGlobalValue`, globalValue);
    return response;
};

export const editGlobalValue = async (globalValue) => {
    const response = await axios.put(`/v1/GlobalValue/UpdateGlobalValue`, globalValue);
    return response;
};

export const deleteGlobalValue = async (globalValueId) => {
    const response = await axios.delete(`/v1/GlobalValue/DeleteGlobalValue`, {
        data: { id: globalValueId }
    });
    return response;
};
