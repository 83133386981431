import { combineReducers } from 'redux';
import menu from './menu';
import productionAreasSlice from './productionAreasSlice';
import productionAreaDetailSlice from './productionAreaDetailSlice';
import operationsSlice from './ReferenceLists/operationsSlice';
import levelsSlice from './ReferenceLists/levelsSlice';
import minesSlice from './ReferenceLists/minesSlice';
import drivesSlice from './ReferenceLists/drivesSlice';
import stopeNumbersSlice from './ReferenceLists/stopeNumbersSlice';
import statusesSlice from './ReferenceLists/statusesSlice';
import globalValuesSlice from './ReferenceLists/globalValuesSlice';

const reducers = combineReducers({
    menu,
    productionAreasSlice,
    productionAreaDetailSlice,
    operationsSlice,
    levelsSlice,
    minesSlice,
    drivesSlice,
    stopeNumbersSlice,
    statusesSlice,
    globalValuesSlice
});
export default reducers;
