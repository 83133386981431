import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useEffect, useState, useContext } from 'react';
import RequestInterceptor from 'RequestInterceptor';
import MsalGroupAuthorisation from 'MsalGroupAuthorisation';
import { AuthContext, AppSettingsContext } from './context';
import { Backdrop, CircularProgress, Typography, Stack } from '@mui/material';

const App = () => {
    const settings = useContext(AppSettingsContext);
    const { login, error } = useMsalAuthentication(InteractionType.Silent, {
        scopes: [settings.AUTH.scopes.read, settings.AUTH.scopes.write]
    });
    const [authContext, setAuthContext] = useState({
        isAdministrator: false,
        isSupervisor: false,
        isUser: false
    });

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, {
                scopes: [settings.AUTH.scopes.read, settings.AUTH.scopes.write]
            });
        }
    }, [error, login, settings]);

    return (
        <>
            <AuthenticatedTemplate>
                <AuthContext.Provider value={[authContext, setAuthContext]}>
                    <MsalGroupAuthorisation>
                        <RequestInterceptor>
                            <ThemeCustomization>
                                <ScrollTop>
                                    <Routes />
                                </ScrollTop>
                            </ThemeCustomization>
                        </RequestInterceptor>
                    </MsalGroupAuthorisation>
                </AuthContext.Provider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <Stack spacing={3} alignItems="center">
                        <CircularProgress color="inherit" />
                        <Typography variant="h4">Signing you in</Typography>
                    </Stack>
                </Backdrop>
            </UnauthenticatedTemplate>
        </>
    );
};

export default App;
