import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllGlobalValues } from 'services/ReferenceLists/globalValueApiService';

// initial state
const initialState = {
    globalValues: [],
    loading: null,
    error: null
};

export const getAllGlobalValuesThunk = createAsyncThunk('globalValues/getAllGlobalValues', async () => {
    const response = await getAllGlobalValues();
    return response;
});

const globalValuesSlice = createSlice({
    name: 'globalValues',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllGlobalValuesThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.globalValues = action.payload.globalValues;
            })
            .addCase(getAllGlobalValuesThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllGlobalValuesThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export default globalValuesSlice.reducer;
