export default function DialogTitle(theme) {
    return {
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    backgroundColor: theme.palette.primary.light
                }
            }
        }
    };
}
