import axios from 'axios';

export const getAllStatuses = async () => {
    const response = await axios.get(`/v1/Status/GetAllStatuses`);
    return response;
};

export const getStatusById = async (id) => {
    const response = await axios.get(`/v1/Status/GetStatusById?Id=${id}`);
    return response.data;
};

export const addStatus = async (status) => {
    const response = await axios.post(`/v1/Status/AddStatus`, status);
    return response;
};

export const editStatus = async (status) => {
    const response = await axios.put(`/v1/Status/UpdateStatus`, status);
    return response;
};

export const deleteStatus = async (statusId) => {
    const response = await axios.delete(`/v1/Status/DeleteStatus`, {
        data: { id: statusId }
    });
    return response;
};
