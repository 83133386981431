import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllMines } from 'services/ReferenceLists/mineApiService';

// initial state
const initialState = {
    mines: [],
    loading: null,
    error: null
};

export const getAllMinesThunk = createAsyncThunk('mines/getAllMines', async () => {
    const response = await getAllMines();
    return response.data;
});

const minesSlice = createSlice({
    name: 'mines',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllMinesThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.mines = action.payload.mines;
            })
            .addCase(getAllMinesThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllMinesThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export default minesSlice.reducer;
