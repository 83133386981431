import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllStatuses } from 'services/ReferenceLists/statusApiService';

// initial state
const initialState = {
    statuses: [],
    loading: null,
    error: null
};

export const getAllStatusesThunk = createAsyncThunk('statuses/getAllStatuses', async () => {
    const response = await getAllStatuses();
    return response.data;
});

const statusesSlice = createSlice({
    name: 'statuses',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllStatusesThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.statuses = action.payload.statuses;
            })
            .addCase(getAllStatusesThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllStatusesThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export default statusesSlice.reducer;
