import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProductionAreaById } from 'services/productionAreaApiService';

// initial state
const initialState = {
    productionArea: null,
    loading: null,
    error: null
};

export const getProductionAreaByIdThunk = createAsyncThunk('productionAreas/getProductionAreaById', async (id) => {
    const response = await getProductionAreaById(id);
    return response;
});

const productionAreaDetailSlice = createSlice({
    name: 'productionAreaDetail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductionAreaByIdThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.productionArea = action.payload;
            })
            .addCase(getProductionAreaByIdThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getProductionAreaByIdThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export default productionAreaDetailSlice.reducer;
