import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import 'simplebar/src/simplebar.css';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';
import { AppSettingsContext } from 'context';
import { Backdrop, CircularProgress, Typography, Stack } from '@mui/material';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <Stack spacing={3} alignItems="center">
            <CircularProgress color="inherit" />
            <Typography variant="h4">Loading</Typography>
        </Stack>
    </Backdrop>
);

fetch(`${process.env.PUBLIC_URL}/appsettings.json`)
    .then((response) => response.json())
    .then((settings) => {
        const msalInstance = new PublicClientApplication({
            auth: {
                clientId: settings.AUTH.clientId,
                authority: settings.AUTH.authority,
                redirectUri: '/'
            },
            cache: {
                cacheLocation: 'sessionStorage', // This configures where your cache will be stored
                storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
            }
        });

        root.render(
            <AppSettingsContext.Provider value={settings}>
                <ReduxProvider store={store}>
                    <BrowserRouter basename="/">
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
                            <MsalProvider instance={msalInstance}>
                                <App />
                            </MsalProvider>
                        </LocalizationProvider>
                    </BrowserRouter>
                </ReduxProvider>
            </AppSettingsContext.Provider>
        );

        // If you want to start measuring performance in your app, pass a function
        // to log results (for example: reportWebVitals(console.log))
        // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
        reportWebVitals();
    });
