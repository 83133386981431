import { List } from '@mui/material';
import { useSelector } from 'react-redux';
import menuItem from 'menu-items';
import NavItem from './NavItem';
import { AuthContext } from 'context';
import { useContext } from 'react';

const Navigation = () => {
    const menu = useSelector((state) => state.menu);
    const { drawerOpen } = menu;
    const [authContext] = useContext(AuthContext);

    const isAdmin = authContext.isAdministrator;
    const navitems = isAdmin
        ? menuItem.items.map((item) => <NavItem key={item.id} item={item} level={1} />)
        : menuItem.items.filter((item) => !item.requiresAdmin).map((item) => <NavItem key={item.id} item={item} level={1} />);

    return <List sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}>{navitems}</List>;
};

export default Navigation;
