import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllDrives } from 'services/ReferenceLists/driveApiService';

// initial state
const initialState = {
    drives: [],
    loading: null,
    error: null
};

export const getAllDrivesThunk = createAsyncThunk('drives/getAllDrives', async () => {
    const response = await getAllDrives();
    return response.data;
});

const drivesSlice = createSlice({
    name: 'drives',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllDrivesThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.drives = action.payload.drives;
            })
            .addCase(getAllDrivesThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllDrivesThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export default drivesSlice.reducer;
