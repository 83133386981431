import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllOperations } from 'services/ReferenceLists/operationApiService';

// initial state
const initialState = {
    operations: [],
    loading: null,
    error: null
};

export const getAllOperationsThunk = createAsyncThunk('operations/getAllOperations', async () => {
    const response = await getAllOperations();
    return response.data;
});

const operationsSlice = createSlice({
    name: 'operations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOperationsThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.operations = action.payload.operations;
            })
            .addCase(getAllOperationsThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllOperationsThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    }
});

export default operationsSlice.reducer;
