import axios from 'axios';

export const getAllDrives = async () => {
    const response = await axios.get(`/v1/Drive/GetAllDrives`);
    return response;
};

export const getDriveById = async (id) => {
    const response = await axios.get(`/v1/Drive/GetDriveById?Id=${id}`);
    return response.data;
};

export const addDrive = async (drive) => {
    const response = await axios.post(`/v1/Drive/AddDrive`, drive);
    return response;
};

export const editDrive = async (drive) => {
    const response = await axios.put(`/v1/Drive/UpdateDrive`, drive);
    return response;
};

export const deleteDrive = async (driveId) => {
    const response = await axios.delete(`/v1/Drive/DeleteDrive`, {
        data: { id: driveId }
    });
    return response;
};
